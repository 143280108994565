@import-normalize;

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: inherit;
  overflow: hidden;
}

.custom-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1390px;
  margin: 0 auto;
  padding-left: 0;
  padding-top: 0.4rem;
  padding-bottom: 0.6rem;
  width: 100%;
  height: 70px;
  border-top: 1px solid #eeeeee;
}

.custom-footer .footer-contents {
  font-size: 0.75rem;
  color: #121212;
}

.product-pip__right-container {
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
}
